import * as React from "react";
import { Chip } from "@mui/material";
import CustomCheckbox from "./CustomCheckbox";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../assets/styles/containers/_commentFilter.scss";
import { Paper, Box, TextField, Typography } from "@mui/material";

export default function LimitTags({ filterTagList, GetCommentList, filterType, setState , state}) {
  function CustomPaper({ children }) {
    return (
      <Paper
        sx={{
          "& .MuiAutocomplete-listbox": {
            "& .MuiAutocomplete-option[aria-selected='true']": {
              bgcolor: "white",

              "&.Mui-focused": {
                bgcolor: "white",
              },
            },
          },
          "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
            bgcolor: "white",
          },
          border: "1px solid #D5D5D5",
          borderRadius: "4px",
          // minWidth: "388px",
        }}
      >
        {children}
      </Paper>
    );
  }
  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={2}
      options={filterTagList || []}
      id="multiple-limit-tags"
      PaperComponent={CustomPaper}
      className="filter-tags-textfield"
      onChange={(e, value) => {
        GetCommentList({ filterByTagId: value?.map((item) => item?.id)?.filter((id) => id !== undefined), filterByType: filterType }, "filter");
        setState((prevState) => ({
          ...prevState,
          selectedFilterTagID: value?.map((item) => item?.id)?.filter((id) => id !== undefined),
          page: 0,
        }));
      }}
      getOptionLabel={(option) => option.title}
      sx={{ marginLeft: "8px" }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            size="small"
            style={{ borderRadius: "35px", border: "1px solid #CED2DA" }}
            deleteIcon={
              <div style={{ backgroundColor: "#97A1AF", borderRadius: "50%", height: "16px", width: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CloseIcon style={{ color: "white", height: "14px", width: "14px" }} />
              </div>
            }
            label={<span className="filter-tags-lable">{option.title}</span>}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option?.id}>
            <Box display={"flex"} flexDirection={"row"} gap={"5px"}>
              <CustomCheckbox checked={selected} />
              <Typography color={"text.primary"} style={{ fontSize: "16px", lineHeight: "24px" }}>
                {option.title}
              </Typography>
            </Box>
          </li>
        );
      }}
      renderInput={(params) => <TextField size="small" {...params} placeholder={params?.inputProps?.value?.length === 0 && state?.selectedFilterTagID?.length === 0 ? "Filter by tags" : ""} />}
    />
  );
}
