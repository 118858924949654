import React, { useRef, useState } from "react";
import uuid from "react-uuid";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import SvgIcon from "@mui/material/SvgIcon";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { arrowDown, copyIcon } from "../../../assets/svgIcons/Svg";
import "../../../assets/styles/containers/_commentLibraryModal.scss";
import FroalaEditorComponent from "../froalaHtmlEditor/FroalaEditorComponent";
import CopySummaryModal from "./CopySummaryModal";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: "14px",
  },
}));

const froalaConfig = {
  heightMin: 127,
  heightMax: 201,
  imagePaste: false,
  spellCheck: true,
  toolbarButtons: {
    moreText: { buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"], align: "left", buttonsVisible: 3 },
    moreRich: { buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"], align: "left", buttonsVisible: 0 },
    moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
  },
  pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
};

function CLAddCommentModal(props) {
  const { state, setState, isEdit } = props;
  const { commentTitle, commentTag, commentSummaryTag, commentText, commentSummary, selectedComment, commentDropdownList, summaryDropdownList } = state;
  const storeData = useSelector((state) => state.commentLibrary);
  const commentEditor = useRef();
  const summaryEditor = useRef();
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  const [summaryCopyModalOpen, setSummaryCopyModalOpen] = useState(false);

  const handleCopyButton = () => {
    if (commentText?.innerHTML?.length === 0 || commentText?.innerHTML === "<p><br></p>") {
      return null;
    } else if (commentSummary?.innerHTML?.length === 0 || commentSummary?.innerHTML === "<p><br></p>") {
      handleCopyComment();
    } else {
      setSummaryCopyModalOpen(true);
    }
  };

  function handleCopyComment() {
    let newCommentText = commentText?.innerHTML;
    let updatedText = newCommentText;
    const newCommentDropdownList = commentDropdownList.map((dropdown) => {
      const uniqueId = uuid();
      const regex = new RegExp(dropdown?.dropdown_uuid, "g");
      updatedText = updatedText.replace(regex, uniqueId);
      return {
        ...dropdown,
        dropdown_uuid: uniqueId,
        name: `dropdown ${uniqueId}`,
        selected_value: dropdown?.selected_value,
      };
    });
    newCommentText = updatedText;
    setState((prevState) => ({ ...prevState, commentSummary: { innerHTML: newCommentText }, summaryDropdownList: newCommentDropdownList }));
    setSummaryCopyModalOpen(false);
    setCopied(true);
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleTagOpen = () => {
    setTagOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTagClose = () => {
    setTagOpen(false);
  };

  const handleChangeTag = (name, value) => {
    const modifiedArray = value.map((obj) => ({
      id: obj.id,
      name: obj.title || obj.name,
      color: obj.color,
    }));
    setState((prevState) => ({ ...prevState, [name]: modifiedArray }));
  };

  const handleEditorChange = (name, content) => {
    setCopied(false);
    setState((prevState) => ({ ...prevState, [name]: { innerHTML: content } }));
  };

  const handleDropdownList = (name, value) => {
    if (name === "commentText") {
      setState((prevState) => ({ ...prevState, commentDropdownList: value }));
    } else {
      setState((prevState) => ({ ...prevState, summaryDropdownList: value }));
    }
  };

  // commented the below code as we are not implementing create new tag feature with the current story
  // const CreateNewTagButton = (options) => {
  //     const classes = useStyles();
  //     const { containerProps, children } = options;
  //     return (
  //         <Paper className={classes.paper} {...containerProps} >
  //             <Button className="add-select" sx={{ textTransform: 'none',margin: "0px 0px 0px 18px" }}>
  //                 <span style={{ fontSize:"18px" }} >+ </span> <span style={{marginLeft:"12px" , fontSize:"18px"}} >Create new tag</span>
  //             </Button>
  //             <Divider />
  //             {children}
  //         </Paper>
  //     );
  // };
  // commented the below code as we are not implementing create new tag feature with the current story
  // const CreateNewSummarySectionButton = (options) => {
  //     const classes = useStyles();
  //     const { containerProps, children } = options;
  //     return (
  //         <Paper className={classes.paper} {...containerProps} >
  //             <Button className="add-select" sx={{ textTransform: 'none',margin: "6px 0px 6px 22px" }}>
  //             <span style={{ fontSize:"18px" }} >+ </span> <span style={{marginLeft:"12px" , fontSize:"18px"}} >Create new summary</span>
  //             </Button>
  //             <Divider />
  //             {children}
  //         </Paper>
  //     );
  // };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Grid item xs={12} style={{ marginTop: "24px" }}>
        <FormControl variant="standard" className="forms-control-library">
          <label className="cl-new-comment-input-form-label" htmlFor="title">
            TITLE
          </label>
          <TextField
            id="cl_title"
            size="small"
            className="cl-dialog-inputs"
            variant="outlined"
            value={commentTitle}
            onChange={(e) => setState((prevState) => ({ ...prevState, commentTitle: e.target.value }))}
            type="text"
          />
        </FormControl>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={5.75} className="cl-add-comment-sections">
          <Grid container className="input-controll">
            <Grid item xs={12} sm={12}>
              <FormControl variant="standard" className="forms-control-library">
                <label className="cl-new-comment-input-form-label" htmlFor="tags">
                  TAGS
                </label>
                <Autocomplete
                  className="autoHover autocompleteCustom"
                  id="tags-outlined"
                  multiple
                  size="small"
                  limitTags={2}
                  options={storeData?.clTagList || []}
                  openOnFocus
                  onClick={!tagOpen ? handleTagOpen : handleTagClose}
                  open={tagOpen}
                  defaultValue={commentTag || []}
                  defaultChecked={commentTag || []}
                  value={commentTag}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.title || ""}
                  filterOptions={(options, { inputValue }) => options.filter((option) => option?.title?.toLowerCase().includes(inputValue.toLowerCase()))}
                  onChange={(e, value) => {
                    const uniqueTags = value.filter((tag, index, self) => index === self.findIndex((t) => t.id === tag.id));
                    handleChangeTag("commentTag", uniqueTags);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseOutlinedIcon />} label={option?.title || option?.name} {...getTagProps({ index })} />)
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option?.id}>
                      <Checkbox style={{ borderRadius: "50%" }} icon={icon} checkedIcon={checkedIcon} checked={commentTag?.some((tag) => tag.id === option.id)} />
                      <Box
                        sx={{ width: "12px", height: "12px", backgroundColor: option?.color, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}
                        style={{
                          marginRight: 8,
                        }}
                      />
                      {option?.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="cl-dialog-inputs"
                      value={commentTag}
                      defaultValue={commentTag}
                      placeholder={commentTag?.length === 0 ? "Add tags" : ""}
                      onClick={!tagOpen ? handleTagOpen : handleTagClose}
                      onBlur={handleTagClose}
                      InputProps={{
                        ...params.InputProps,
                        style: { fontFamily: "Open Sans!important", fontSize: "14px", fontWeight: 400, paddingLeft: commentTag?.length !== 0 ? "0px" : "20px" },
                        endAdornment: (
                          <>
                            <SearchIcon className={`customSearch ${commentTag?.length !== 0 ? "hidden" : ""}`} style={{ color: "#9b9b9b", marginRight: "8px" }} />
                            <SvgIcon className={`customArrowDown ${tagOpen ? "arrowActive" : ""}`} viewBox="" onClick={!tagOpen ? handleTagOpen : handleTagClose}>
                              {arrowDown}
                            </SvgIcon>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant="standard" className="forms-control-library">
                <label className="cl-new-comment-input-form-label" htmlFor="comment">
                  COMMENT
                </label>
                <FroalaEditorComponent
                  commentData={{ commentText: commentText, comment_dropdowns: commentDropdownList }}
                  editor={commentEditor}
                  onChange={handleEditorChange}
                  id={"commentText"}
                  editorData={commentText}
                  froalaConfig={froalaConfig}
                  isEdit={isEdit}
                  handleDropdownList={handleDropdownList}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={0.5} display={"flex"} alignItems={"center"} justifyContent={"center"} pl={0.2} pt={14}>
          <BootstrapTooltip title={copied ? "Copied" : "Copy comment content"} style={{ fontSize: "28px" }}>
            <IconButton sx={{ padding: "0px" }} className="summary-section-copy-icon" onClick={handleCopyButton}>
              <SvgIcon className="copyIcon" viewBox="">
                {copyIcon}
              </SvgIcon>
            </IconButton>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={12} sm={5.75} className="cl-add-comment-sections">
          <Grid container className="input-controll">
            <Grid item xs={12} sm={12}>
              <FormControl variant="standard" className="forms-control-library">
                <label className="cl-new-comment-input-form-label" htmlFor="summary">
                  SUMMARY SECTION
                </label>
                <Autocomplete
                  className="autoHover autocompleteSummaryCustom"
                  id="tags-outlined"
                  multiple
                  size="small"
                  limitTags={2}
                  options={storeData?.clSummaryTagList || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.title || ""}
                  filterOptions={(options, { inputValue }) => options.filter((option) => option?.title?.toLowerCase().includes(inputValue.toLowerCase()))}
                  openOnFocus
                  open={open}
                  defaultChecked={commentSummaryTag || []}
                  defaultValue={commentSummaryTag || []}
                  value={commentSummaryTag}
                  onChange={(e, value) => {
                    const uniqueTags = value.filter((tag, index, self) => index === self.findIndex((t) => t.id === tag.id));
                    handleChangeTag("commentSummaryTag", uniqueTags);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        deleteIcon={<CloseOutlinedIcon />}
                        label={
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: `${option?.color} !important`,
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "8px",
                              }}
                            />
                            <div style={{ fontSize: "14px" }}>{option?.name || option?.title}</div>
                          </Box>
                        }
                        {...getTagProps({ index })}
                        sx={{
                          padding: "2px 2px 2px 10px !important",
                          fontSize: "10px !important",
                        }}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option?.id}>
                      <Checkbox style={{ borderRadius: "50%" }} icon={icon} checkedIcon={checkedIcon} checked={commentSummaryTag?.some((tag) => tag.id === option.id)} />
                      <Box
                        sx={{ width: "12px", height: "12px", backgroundColor: option?.color, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}
                        style={{
                          marginRight: 8,
                        }}
                      />
                      {option?.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="cl-dialog-inputs"
                      defaultValue={commentSummaryTag}
                      value={commentSummaryTag}
                      placeholder={commentSummaryTag?.length === 0 ? "No summary selected" : ""}
                      onClick={!open ? handleOpen : handleClose}
                      onBlur={handleClose}
                      InputProps={{
                        ...params.InputProps,
                        style: { fontFamily: "Open Sans!important", fontSize: "14px", fontWeight: 400 },
                        endAdornment: (
                          <>
                            <SvgIcon className={`customArrowDown ${open ? "arrowActive" : ""}`} viewBox="" onClick={!open ? handleOpen : handleClose}>
                              {arrowDown}
                            </SvgIcon>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant="standard" className="forms-control-library">
                <label className="cl-new-comment-input-form-label" htmlFor="summary_comment">
                  SUMMARY COMMENT
                </label>
                <FroalaEditorComponent
                  commentData={{ commentSummary: commentSummary, summary_comment_dropdowns: summaryDropdownList }}
                  editor={summaryEditor}
                  onChange={handleEditorChange}
                  id={"commentSummary"}
                  editorData={commentSummary}
                  froalaConfig={froalaConfig}
                  isEdit={isEdit}
                  handleDropdownList={handleDropdownList}
                  copy={copied}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {summaryCopyModalOpen && (
        <CopySummaryModal
          open={summaryCopyModalOpen}
          handleClose={setSummaryCopyModalOpen}
          handleSubmit={handleCopyComment}
          submitButtonTitle={"REPLACE"}
          dialogTitle={"Replace summary comment ?"}
          dialogContentText={"Are you sure you want to replace the summary comment ?"}
        />
      )}
    </>
  );
}

export default CLAddCommentModal;
