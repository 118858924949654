import React, { useState } from "react";
import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import LibraryTable from "../../components/CommentLibrary/LibraryTable";
import CLSummary from "../../components/CommentLibrary/CommentLibraryTags/CLSummary";
import CLTags from "../../components/CommentLibrary/CommentLibraryTags/CLTags";
import { useSelector } from "react-redux";
import { apiCall } from "../../utils/action";

const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 75,
    borderRadius: 5,
    width: "100%",
    height: "30px !important",
    backgroundColor: "#0062A0",
  },
});

const StyledTab = styled((props) => (
  <Tab
    style={{
      width: props?.width,
      textTransform: "none",
      fontWeight: "500",
      fontFamily: '"Source Sans Pro", sans-serif !important',
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      marginRight: "8px",
      color: "#42474E",
    }}
    disableRipple
    {...props}
  ></Tab>
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: "500 !important",
  fontFamily: '"Source Sans Pro", sans-serif !important',
  fontStyle: "normal !important",
  fontSize: theme.typography.pxToRem(14),
  lineHeight: "20px !important",
  letterSpacing: "0.1px !important",
  marginRight: theme.spacing(1),
  color: "#42474E !important",
  "&.Mui-selected": {
    color: "#0062A0 !important",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#0062A0 !important",
  },
}));

/**
 * LibraryTabs component
 *
 * Renders library tabs and passes the selected tab value to the LibraryTable component
 */
function LibraryTabs() {
  /**
   * Tracks the selected tab value
   */
  const [tabValue, setTabValue] = useState(0);
  const matches = useMediaQuery("(min-width:900px)");
  const storeData = useSelector((state) => state.commentLibrary);
    const [state, setState] = useState({
      page: 0,
      order: "asc",
      search: false,
      orderBy: "",
      selected: [],
      expandedRows: [],
      commentListData: null,
      commentListInitialData: null,
      commentTitle: "",
      commentTag: [],
      commentSummaryTag: [],
      commentText: { innerHTML: "" },
      commentSummary: { innerHTML: "" },
      showLoader: false,
      searchText: "",
      filterTagList: storeData?.clTagList,
      selectedFilterTagID: [],
      totalRowCount: -1,
      filterType: "all",
      listLoaded: false,
      globalSearchTableData: [],
      openAddDialog: false,
      commentDropdownList: [],
      summaryDropdownList: [],
      isEdit: false,
    });

  /**
   * Handles tab changes
   * @param {object} event - the event object
   * @param {number} newValue - the index of the selected tab
   */

    const GetCommentList = (formData, type) => {
      /**
       * Fetch comment list data from API
       * @param {number} pageNumber current page number
       */
      setState((prevState) => ({ ...prevState, showLoader: true }));
      apiCall(
        (response) => {
          const { data, message, success, tagsList } = response.data;
          if (success) {
            setState((prevState) => ({ ...prevState, commentListData: data?.data, totalRowCount: data?.total, search: false, listLoaded: true, commentListInitialData: data?.data, showLoader: false }));
            if (type !== "filter") {
              setState((prevState) => ({ ...prevState, filterTagList: tagsList }));
            }
          } else {
            console.log("else", message);
          }
        },
        formData,
        "GET_COMMENT_LIBRARY_LIST"
      );
    };
  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
    if (newTabValue === 0) {
      GetCommentList({ page:  1 });
    }
  };

  return (
    <div className="library-tabs">
      <StyledTabs value={tabValue} className="styled-tab" onChange={handleTabChange} aria-label="styled tabs example">
        <StyledTab label="Comments" width={matches ? "215px" : ""} />
        {/* <StyledTab label="Media" width={matches ? "215px" : ""} /> */}
        <StyledTab label="Tags" width={matches ? "215px" : ""} />
        <StyledTab label="Summaries" width={matches ? "215px" : ""} />
      </StyledTabs>
      <Divider sx={{ border: "0px 0px 1px 0px !important", color: "#D5D5D5" }} />
      {tabValue === 0 && <LibraryTable setState={setState} state={state} GetCommentList={GetCommentList} />}
      {tabValue === 1 && <CLTags />}
      {tabValue === 2 && <CLSummary />}
    </div>
  );
}

export default LibraryTabs;
