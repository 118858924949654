import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import parse from "html-react-parser";
import FilterTags from "./FilterTags";
import CommentTags from "./CommentTags";
import ButtonGroup from "./ButtonGroup";
import { visuallyHidden } from "@mui/utils";
import SummaryRating from "./SummaryRating";
import CustomDropdown from "./CustomDropdown";
import AddIcon from "@mui/icons-material/Add";
import Preloader from "../../helpers/Preloader";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box, Grid, TextField, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Paper, Button, IconButton, TableHead, TableSortLabel } from "@mui/material";
import { getCommentLibraryList, updateDropDownValues } from "../../store/action/CommentLibraryAction";
import { stableSort } from "./CommentLibraryHelper";
import FilterButtonGroup from "./FilterButtonGroup";
import AddCommentDialog from "./AddCommentDialog";
import DeleteCommentDialog from "./DeleteCommentDialog";
import "../../../assets/styles/containers/_commentLibrary.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index} // Hides the tab panel when it's not selected
      id={`simple-tabpanel-${index}`} // Generates a unique ID for each tab panel
      aria-labelledby={`simple-tab-${index}`} // The ID of the tab
      {...other} // Spreads any other properties
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

/**
 * TablePaginationActions component
 *
 * @param {object} props - The properties of the component
 * @param {number} props.count - The total number of rows
 * @param {number} props.page - The current page
 * @param {number} props.rowsPerPage - The rows per page
 * @param {function} props.onPageChange - The function to call when the page changes
 * @returns {JSX.Element}
 */
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  /**
   * Handles the click of the first page button
   *
   * @param {React.MouseEvent<HTMLElement>} event - The click event
   */
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  /**
   * Handles the click of the back button
   *
   * @param {React.MouseEvent<HTMLElement>} event - The click event
   */
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  /**
   * Handles the click of the next button
   *
   * @param {React.MouseEvent<HTMLElement>} event - The click event
   */
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  /**
   * Handles the click of the last page button
   *
   * Calculates the last page based on the total number of rows
   * and the rows per page, and passes that value to the onPageChange function
   *
   * @param {React.MouseEvent<HTMLElement>} event - The click event
   */
  const handleLastPageButtonClick = (event) => {
    const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
    onPageChange(event, lastPage);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page" sx={{ ml: 3 }}>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page" sx={{ ml: 3 }}>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page" sx={{ ml: 3 }}>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

/**
 * Creates a comparator function for sorting rows.
 *
 * @param {string} order - The sort order, either 'asc' or 'desc'
 * @param {string} orderBy - The property to sort by
 * @returns {function} - The comparator function
 */
function getComparator(order, orderBy) {
  /**
   * Comparator function that compares two rows based on the orderBy property
   *
   * @param {object} a - The first row
   * @param {object} b - The second row
   * @returns {number} - A negative number if a < b, positive if a > b, or 0 if equal
   */
  const descendingComparator = (a, b, orderBy) => {
    // Check if orderBy property exists in both objects
    if (!(orderBy in a) || !(orderBy in b)) {
      return 0; // Unable to compare, consider them equal
    }
    // If the property is a string, lowercase both values to compare
    if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
      const lowercaseA = (a[orderBy] || "")
        ?.replace(/<[^>]*>| /g, "")
        ?.replace(/\s/g, "")
        ?.toLowerCase();
      const lowercaseB = (b[orderBy] || "")
        ?.replace(/<[^>]*>| /g, "")
        ?.replace(/\s/g, "")
        ?.toLowerCase();
      if (lowercaseA < lowercaseB) {
        return 1; // Return 1 for ascending order
      } else if (lowercaseA > lowercaseB) {
        return -1; // Return -1 for descending order
      }
    }
    // If the property is a number, compare normally
    if (a[orderBy] < b[orderBy]) {
      return 1; // Return 1 for ascending order
    } else if (a[orderBy] > b[orderBy]) {
      return -1; // Return -1 for descending order
    }
    // Items are equal
    return 0;
  };
  // Based on the order, sort the rows in either ascending or descending order
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

const CommentLibraryTable = ({state, setState, GetCommentList}) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.commentLibrary);
  const rowsPerPage = 10;

  const { page, search, order, orderBy, selected, expandedRows, commentListData, commentListInitialData, showLoader, filterTagList, selectedFilterTagID, filterType, openAddDialog } = state;



  // useEffect(() => {
  //   dispatch(getCommentLibraryList(1));
  //   setState((prevState) => ({ ...prevState, commentListData: storeData.commentLibraryList || [] }));
  // }, []);

  useEffect(() => {
    async function callApi() {
      await dispatch(getCommentLibraryList(1));
    }
    if (!storeData?.commentLibraryList) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      callApi();
      setState((prevState) => ({ ...prevState, value: 0, isLoading: false }));
    }
    if (storeData?.totalListCount && !storeData.commentLibraryWholeList) {
      dispatch(getCommentLibraryList(null, storeData?.totalListCount));
    }
    
  }, [storeData?.totalListCount, storeData?.commentLibraryList, dispatch]);

  useEffect(() => {
    if (storeData.commentLibraryWholeList && !commentListInitialData) {
      setState((prevState) => ({ ...prevState, commentListInitialData: storeData.commentLibraryWholeList || [] }));
    }
    if (storeData.commentLibraryList && !commentListData) {
      setState((prevState) => ({ ...prevState, commentListData: storeData.commentLibraryList || [] }));
      dispatch(getCommentLibraryList(1));
    }
  }, [storeData.commentLibraryWholeList, storeData?.commentLibraryList]);

  useEffect(() => {
    if (!filterTagList && storeData?.clTagList) setState((prevState) => ({ ...prevState, filterTagList: storeData?.clTagList || [] }));
  }, [filterTagList, storeData?.clTagList]);

  const handleSearchFilter = (event) => {
    setState((prevState) => ({ ...prevState, searchText: event }));
    const query = event.toLowerCase();
    if (query === "") {
      setState((prevState) => ({ ...prevState, page: 0, search: false, commentListData: storeData?.commentLibraryList || [] }));
    } else {
      const searchData = commentListInitialData?.filter((item) => {
        return item?.comment_text?.toLowerCase()?.includes(query) || item?.title?.toLowerCase()?.includes(query);
      });
      setState((prevState) => ({ ...prevState, page: 0, search: true, commentListData: searchData }));
    }
  };

  // Handle head cells, sorting and selection
  const EnhancedTableHead = (props) => {
    /**
     * Handle table head cells, sorting and selection
     * @param {object} props table head props
     */
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false} className="cl-table-head">
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() => (headCell.id !== "expand_icon" && headCell.id !== "global" ? <UnfoldMoreIcon className="cl-sort-icon" /> : "")}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const headCells = [
    {
      id: "expand_icon",
      numeric: false,
      disablePadding: true,
      label: <LastPageIcon className="cl-table-exp-icon" onClick={toggleAllRowsExpansion} />,
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
    },
    {
      id: "comment",
      numeric: false,
      disablePadding: false,
      label: "Comment",
    },
    {
      id: "summary",
      numeric: false,
      disablePadding: false,
      label: "Summary",
    },
    {
      id: "times_used",
      numeric: false,
      disablePadding: false,
      label: "Times used",
    },
    {
      id: "updated_at",
      numeric: false,
      disablePadding: false,
      label: "Last modified",
    },
    {
      id: "global",
      numeric: false,
      disablePadding: true,
      label: "",
    },
  ];

  /**
   * Toggle the expansion of a given row in the comment library table.
   *
   * @param {number} rowId - the id of the row to toggle expansion of
   * @returns {void}
   */
  const toggleRowExpansion = (rowId) => {
    /**
     * Toggles the expansion state of a given row in the comment library table.
     * If the row is currently expanded, we will collapse it.
     * If the row is currently collapsed, we will expand it.
     *
     * @param {Array<number>} prevExpandedRows - the current expanded rows
     * @returns {Array<number>} the updated expanded rows
     */
    setState((prevState) => ({ ...prevState, expandedRows: expandedRows.includes(rowId) ? expandedRows.filter((id) => id !== rowId) : [...expandedRows, rowId] }));
  };

  const isRowExpanded = (rowId) => expandedRows.includes(rowId);

  function toggleAllRowsExpansion() {
    const allRowIds = commentListData.map((row) => row.id);
    setState((prevState) => ({ ...prevState, expandedRows: expandedRows.length === allRowIds.length ? [] : allRowIds }));
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setState((prevState) => ({ ...prevState, order: isAsc ? "desc" : "asc", orderBy: property }));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedComment = commentListData.map((n) => n.id);
      setState((prevState) => ({ ...prevState, selected: selectedComment }));
      return;
    }
    setState((prevState) => ({ ...prevState, selected: [] }));
  };

  function replaceDropdownsWithSelects(htmlString, dropdownValues, type, comment_id, comment_uuid) {
    var uuidPattern = /\[dropdown ([a-f0-9-]+)\]/g;
    var uuids = htmlString?.match(uuidPattern);
    let singleUuidPattern = /\[dropdown ([a-f0-9-]+)\]/;
    const regex = /Â/g;
    function decodeEntities(encodedString) {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = encodedString;
      return textarea.value;
    }
    if (uuids) {
      uuids?.forEach(function (uuid) {
        let uuidSingle = uuid.match(singleUuidPattern)[1];
        let commentDropValues = dropdownValues?.find((el) => el.dropdown_uuid === uuidSingle);
        let newDropdownValues = commentDropValues?.dropdowns_values || [];
        newDropdownValues = Array.isArray(newDropdownValues) && newDropdownValues?.filter((obj) => !obj?.toUpperCase()?.includes("XXXXX"));
        if (Array.isArray(newDropdownValues) && !newDropdownValues?.map((value) => value?.toUpperCase())?.some((value) => value?.includes("------"))) {
          newDropdownValues?.unshift("-------------");
        }
        var selectHtml = React.createElement(CustomDropdown, {
          dropdownUuid: uuidSingle || "",
          commentUuid: comment_uuid || "",
          commentId: comment_id || "",
          type: type || "",
          options: newDropdownValues || [],
          selectedValue: commentDropValues?.selected_value || "",
        });
        htmlString = htmlString?.replace(uuid, ReactDOMServer.renderToStaticMarkup(selectHtml));
      });
    } else {
      var parser = new DOMParser();
      var doc = parser.parseFromString(htmlString, "text/html");
      var selectTags = doc.querySelectorAll("select");
      selectTags.forEach((select) => {
        let uuidSingle = select.getAttribute("id");
        let commentDropValues = dropdownValues?.find((el) => el.dropdown_uuid === uuidSingle);
        let newDropdownValues = commentDropValues?.dropdowns_values || [];
        newDropdownValues = Array.isArray(newDropdownValues) && newDropdownValues?.filter((obj) => !obj?.toUpperCase()?.includes("XXXXX"));
        if (Array.isArray(newDropdownValues) && !newDropdownValues?.map((value) => value?.toUpperCase())?.some((value) => value?.includes("------"))) {
          newDropdownValues?.unshift("-------------");
        }
        var selectHtml = React.createElement(CustomDropdown, {
          dropdownUuid: uuidSingle || "",
          commentUuid: comment_uuid || "",
          commentId: comment_id || "",
          type: type || "",
          options: newDropdownValues || [],
          selectedValue: commentDropValues?.selected_value || "",
        });
        let selectOuterHtml = select.outerHTML;
        htmlString = htmlString.replace(selectOuterHtml, ReactDOMServer.renderToStaticMarkup(selectHtml));
      });
    }
    return parse(decodeEntities(htmlString?.replace(regex, "")?.replace(/Ã/g, "")));
  }

  if (!openAddDialog) {
    let commentDropElements = document.querySelectorAll(".comment-cl-dropdown");
    commentDropElements?.forEach((element) => {
      const commentId = element.getAttribute("comment_id");
      const dropType = element.getAttribute("dropType");
      const commentUUId = element.getAttribute("comment_uuid");
      const dropdownUUID = element.getAttribute("dropdown_uuid");
      const commentDisabled = element.getAttribute("disabled");
      if (element !== null && element !== undefined) {
        element.onchange = (event) => handleChangeDropdown(event, dropdownUUID, commentId, dropType, commentUUId, commentDisabled);
      }
    });
    let summaryDropElements = document.querySelectorAll(".summary-cl-dropdown");
    summaryDropElements?.forEach((element) => {
      const commentId = element.getAttribute("comment_id");
      const dropType = element.getAttribute("dropType");
      const commentUUId = element.getAttribute("comment_uuid");
      const dropdownUUID = element.getAttribute("dropdown_uuid");
      const commentDisabled = element.getAttribute("disabled");
      if (element !== null && element !== undefined) {
        element.onchange = (event) => handleChangeDropdown(event, dropdownUUID, commentId, dropType, commentUUId, commentDisabled);
      }
    });
  }
  const handleClickChangeDropdown = (event, id, row, action) => {
    !isRowExpanded(id) && toggleRowExpansion(row.id);
    const selectedIndex = selected.indexOf(row.uuid);
    if (action === "dropdown") {
      if (!isSelected(row.uuid)) {
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, row.uuid);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setState((prevState) => ({ ...prevState, selected: newSelected }));
      }
    }
  };

  const handleChangeDropdown = async (event, dropdownUuid, commentId, type, commentUUId, commentDisabled) => {
    commentDisabled === "false" && handleClickChangeDropdown(event, commentId, { id: commentId, uuid: commentUUId }, "dropdown");
    const selectedValue = event.target.value;
    const dropdownValuesArrays = commentListData.map((item) => {
      const commentDropdowns = item.comment_dropdowns || [];
      const summaryDropdowns = item.summary_comment_dropdowns || [];
      const allDropdowns = [...commentDropdowns, ...summaryDropdowns];
      return allDropdowns.filter((dropdown) => dropdown.dropdown_uuid === dropdownUuid).map((dropdown) => dropdown.dropdowns_values);
    });
    const dropdownValues = dropdownValuesArrays.flat().find((values) => values.length > 0);
    let payload = {
      comment_id: commentId,
      dropdown_uuid: dropdownUuid,
      dropdown_type: type === "comment" ? "1" : "2",
      selected_value: selectedValue,
      dropdown_values: dropdownValues,
    };
    await dispatch(updateDropDownValues(payload));
  };

  // Handle click is not implement in phase 1
  // const handleClick = (event, id, row) => {
  //     const selectedIndex = selected.indexOf(row.id);
  //     let newSelected = [];

  //     if (selectedIndex === -1) {
  //         newSelected = newSelected.concat(selected, row.id);
  //     } else if (selectedIndex === 0) {
  //         newSelected = newSelected.concat(selected.slice(1));
  //     } else if (selectedIndex === selected.length - 1) {
  //         newSelected = newSelected.concat(selected.slice(0, -1));
  //     } else if (selectedIndex > 0) {
  //         newSelected = newSelected.concat(
  //             selected.slice(0, selectedIndex),
  //             selected.slice(selectedIndex + 1),
  //         );
  //     }
  //     setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setState((prevState) => ({ ...prevState, page: newPage, expandedRows: [] }));
    GetCommentList({ page: newPage + 1, filterByTagId: selectedFilterTagID, filterByType: filterType });
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const visibleRows = stableSort(commentListData, getComparator(order, orderBy));

  return (
    <>
      <AddCommentDialog state={state} setState={setState} />
      <DeleteCommentDialog state={state} setState={setState} />
      <Preloader showPreloader={showLoader} />
      <Box sx={{ width: "100%", padding: "30px" }}>
        <Grid container className="cl-search-container">
          <Grid item xs={6} md={6} lg={2} className="cl-search-bar">
            <div className="cl-search">
              <TextField id="search-bar" onChange={(event) => handleSearchFilter(event.target.value)} variant="outlined" placeholder="Search in table" size="small" />
              <SearchIcon className="cl-search-icon" />
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={3.5}>
            <FilterTags filterTagList={filterTagList} GetCommentList={GetCommentList} filterType={filterType} setState={setState} state={state} />
          </Grid>
          <Grid item xs={4} md={6} lg={3}>
            <FilterButtonGroup GetCommentList={GetCommentList} selectedFilterTagID={selectedFilterTagID} setState={setState} />
          </Grid>
          <Grid item xs={8} md={6} lg={3.5} sx={{ gap: "5px" }}>
            {/* <Button startIcon={<SettingsOutlinedIcon />} className="options">
              <span>Options</span>
            </Button> */}
            <Button startIcon={<AddIcon />} className="create-new-btn" onClick={() => setState((prevState) => ({ ...prevState, openAddDialog: true }))}>
              <span>Create new</span>
            </Button>
          </Grid>
          <Grid item xs={12} md={1.5}></Grid>
        </Grid>
        <Paper sx={{ width: "100%", mb: 2, border: "1px solid #D5D5D5", borderRadius: "3px" }} className="cl-table" elevation={0}>
          <TableContainer className="cl-table-container">
            <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={commentListData?.length}
              />
              <TableBody>
                {visibleRows?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover aria-checked={isItemSelected} tabIndex={1} key={row.id} selected={isItemSelected} sx={{ cursor: "pointer", borderLeft: "1px solid blue" }}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="cl-row-status cl-exp-col"
                        sx={{ borderLeft: `${isRowExpanded(row.id) ? "1px solid #0062A0" : "none"}` }}
                      >
                        <IconButton onClick={() => toggleRowExpansion(row.id)} style={{ marginLeft: "15px" }}>
                          {isRowExpanded(row.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell className="cl-title-col" align="left">
                        <div className={`cl-comment-title ${isRowExpanded(row.id) ? "expanded" : ""}`}>{row?.title}</div>
                      </TableCell>
                      <TableCell className="cl-comment-col" align="left">
                        <div className={`comment-text ${isRowExpanded(row.id) ? "expanded" : ""}`} onClick={() => (!isRowExpanded(row.id) ? toggleRowExpansion(row.id) : undefined)}>
                          {row?.comment_text !== null && replaceDropdownsWithSelects(row?.comment_text?.replace(/<\/?[^>]+(>|$)/g, ""), row?.comment_dropdowns, "comment", row.id, row.uuid)}
                        </div>
                        <CommentTags tags={row?.tags} />
                      </TableCell>
                      <TableCell className="cl-summary-col" align="left">
                        <div className={`summary-text ${isRowExpanded(row.id) ? "expanded" : ""}`}>
                          {row?.summary_comment_text !== null &&
                            replaceDropdownsWithSelects(row?.summary_comment_text?.replace(/<\/?[^>]+(>|$)/g, ""), row?.summary_comment_dropdowns, "summary", row.id, row.uuid)}
                        </div>
                        <SummaryRating rating={row?.summaries} />
                      </TableCell>
                      <TableCell className="cl-rating-col" align="left">
                        {row?.times_used}
                      </TableCell>
                      <TableCell className="cl-global-col" align="left" style={{ width: "200px", position: "relative" }}>
                        <span className="date-placeholder-comment">{moment(row?.updated_at)?.format("MM/DD/YYYY")}</span>
                        <span className="time-placeholder">{moment(row?.updated_at)?.format("LT")}</span>
                      </TableCell>
                      <TableCell align="right" width="160px" sx={{ position: "relative" }}>
                        <ButtonGroup state={state} row={row} setState={setState} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <TablePagination
          rowsPerPageOptions={rowsPerPage}
          component="div"
          count={search ? commentListData?.length : storeData?.totalListCount}
          rowsPerPage={search ? commentListData?.length : rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
    </>
  );
};

export default CommentLibraryTable;
