import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Froalaeditor from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
// import "froala-editor/js/plugins/image.min.js";
// import "froala-editor/css/plugins/image.min.css";
import "froala-editor/js/plugins/word_paste.min.js";
import uuid from "react-uuid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "./FroalaEditorStyles.scss";
import BulbIcon from "../../../assets/images/bulbIcon.png";
import { decodeEntities } from "../../utils/services";
import EditOptionsDialog from "./EditOptionsDialog";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CopySummaryModal from "../TemplateUtils/CopySummaryModal";
import { set } from "date-fns";

const FroalaEditorComponent = (props) => {
  const { editor, id, onChange, editorData, onBlur, froalaConfig, commentData, handleDropdownList, isEdit, copy } = props;
  const [state, setState] = useState({
    open: false,
    selectedDropdown: null,
    newOption: "",
    hoverOption: null,
    textfield: false,
    editedOption: null,
    dialogOptionList: [],
    dropdownList: [],
    openHelp: false,
    isFocused: false,
    openRemoveDropdown: false,
  });
  const { openHelp, dropdownList, selectedDropdown, dialogOptionList, isFocused, openRemoveDropdown } = state;
  const location = useLocation();
  const storeData = useSelector((state) => state.commentLibrary);

  const handleOpenDialog = (event, id) => {
    event?.stopPropagation();
    let newButtonId = event?.target?.id?.replace("dropdownButton", "");
    if (event?.target?.tagName === "BUTTON") {
      let dropdown;
      if (id) dropdown = dropdownList.find((obj) => obj?.dropdown_uuid === id);
      else dropdown = dropdownList.find((obj) => obj?.dropdown_uuid === newButtonId);
      if (dropdown === undefined) dropdown = dropdownList.find((obj) => obj?.isPasted === true);
      setState((prevState) => ({
        ...prevState,
        dialogOptionList: dropdown?.dropdowns_values || [],
        open: true,
        selectedDropdown: newButtonId || id || dropdown?.dropdown_uuid,
      }));
    }
  };

  useEffect(() => {
    const editorInstance = editor?.current?.editor;
    let editorContent = editorInstance?.html?.get();
    if (!editorInstance || !editorData?.innerHTML) return;
    let isEffectExecuted = false;
    let newDropdownList = [...dropdownList];
    const replacePlaceholdersWithDropdowns = () => {
      const dropdowns = id === "introTextTE" ? commentData?.introductory_text_dropdowns : id === "commentText" ? commentData?.comment_dropdowns : commentData?.summary_comment_dropdowns;
      const regexPlainDropdown = /\[a-f0-9\-]+\]/g;
      if (Array?.isArray(dropdowns) && dropdowns?.length > 0) {
        if (copy) {
          const tempContainer = document.createElement("div");
          tempContainer.innerHTML = editorData?.innerHTML;
          dropdowns.forEach((dropdown) => {
            const spanElements = Array.from(tempContainer.querySelectorAll(`span[contenteditable="false"][id="[dropdown ${dropdown.dropdown_uuid}]"]`));
            spanElements.forEach((spanElement) => {
              let optionValues = [];
              spanElement.innerHTML = "";
              const selectElement = document.createElement("select");
              let newDropdownValues = Array.isArray(dropdown?.dropdowns_values?.values) ? dropdown?.dropdowns_values?.values : dropdown?.dropdowns_values;
              if (Array.isArray(newDropdownValues)) {
                newDropdownValues = newDropdownValues.filter((obj) => !obj.toUpperCase().includes("XXXXX"));
                if (!newDropdownValues.find((value) => value.includes("------"))) {
                  newDropdownValues.unshift("-------------");
                }
                newDropdownValues.forEach((value) => {
                  const option = document.createElement("option");
                  option.text = value?.length > 40 ? `${value.substring(0, 40)}...` : value || "";
                  option.value = value || "";
                  option.title = value || "";
                  if (dropdown?.selected_value === value) {
                    option.setAttribute("selected", value);
                  }
                  selectElement.appendChild(option);
                });
              }
              selectElement.classList.add(`${id === "introTextTE" ? "intro" : id === "commentText" ? "comment" : "summary"}-cl-dropdown`);
              selectElement.setAttribute("id", dropdown.dropdown_uuid);
              if (id !== "introTextTE") {
                selectElement.setAttribute("comment_id", commentData?.comment_id);
                selectElement.setAttribute("comment_uuid", commentData?.comment_uuid);
                selectElement.setAttribute("dropType", id === "commentText" ? "comment" : "summary");
              }
              optionValues = Array.from(selectElement.options)
                .map((option) => option.value)
                .filter((value) => value !== "-------------");
              newDropdownList.push({
                dropdown_uuid: dropdown.dropdown_uuid,
                name: `dropdown ${dropdown.dropdown_uuid}`,
                selected_value: dropdown.selected_value,
                dropdowns_values: optionValues || [],
                isPasted: true,
              });
              spanElement.appendChild(selectElement);
              const buttonElement = document.createElement("button");
              buttonElement.setAttribute("id", `dropdownButton${dropdown.dropdown_uuid}`);
              buttonElement.setAttribute("contenteditable", "false");
              buttonElement.setAttribute("name", `${dropdown.dropdown_uuid}`);
              buttonElement.classList.add("dropdown-button");
              buttonElement.setAttribute("type", "button");
              buttonElement.addEventListener("click", (event) => {
                handleOpenDialog(event, dropdown.dropdown_uuid);
              });
              buttonElement.style.cssText = `background-color: #f1f9fe;
                  width: 24px;
                  height: 24px;
                  padding: 0px;
                  color: #0F75BC;
                  border-radius: 4px 0px 0px 4px;
                  border: 1px solid #0F75BC;
                  position: relative;
                  transform: scaleX(-1);
                  font-size: 17px;
                  margin-left: -4px;
                  top: 2.5px;`;
              buttonElement.textContent = "✎";
              spanElement.appendChild(buttonElement);
              const styleTag = document.createElement("style");
              styleTag.innerHTML = `
                @media screen and (max-width: 1100px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 25.5px;
                  }
                }
                @media screen and (min-width: 1600px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 2.5px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
                @media screen and (min-width: 1700px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 2.5px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
                @media screen and (min-width: 1800px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 2.5px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
                @media screen and (min-width: 1900px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 2px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
                @media screen and (min-width: 2000px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 1.5px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
                @media screen and (min-width: 2100px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 1.5px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
              `;
              document.head.appendChild(styleTag);
            });
          });
          editorData.innerHTML = tempContainer.innerHTML;
        } else {
          dropdowns?.forEach((dropdown) => {
            let optionValues = [];
            const placeholder = `\\[dropdown ${dropdown.dropdown_uuid}\\]`;
            const regexPlain = new RegExp(placeholder, "g");
            const selectElement = document.createElement("select");
            if (Array?.isArray(dropdown?.dropdowns_values?.values)) {
              let newDropdownValues = dropdown?.dropdowns_values?.values;
              newDropdownValues = newDropdownValues?.filter((obj) => !obj?.toUpperCase()?.includes("XXXXX"));
              if (!newDropdownValues?.find((value) => value?.includes("------"))) {
                newDropdownValues?.unshift("-------------");
              }
              newDropdownValues?.forEach((value) => {
                const option = document.createElement("option");
                if (value?.length > 40) option.text = `${value?.substring(0, 40)}...`;
                else option.text = value || "";
                option.value = value || "";
                option.title = value || "";
                if (dropdown?.selected_value === value) {
                  option.setAttribute("selected", value);
                }
                selectElement.appendChild(option);
              });
            } else if (Array?.isArray(dropdown?.dropdowns_values)) {
              let newDropdownValues = dropdown?.dropdowns_values;
              newDropdownValues = newDropdownValues?.filter((obj) => !obj?.toUpperCase()?.includes("XXXXX"));
              if (!newDropdownValues?.find((value) => value?.includes("------"))) {
                newDropdownValues?.unshift("-------------");
              }
              newDropdownValues?.forEach((value) => {
                const option = document.createElement("option");
                if (value?.length > 40) option.text = `${value?.substring(0, 40)}...`;
                else option.text = value || "";
                option.value = value || "";
                option.title = value || "";
                if (dropdown?.selected_value === value) {
                  option.setAttribute("selected", value);
                }
                selectElement.appendChild(option);
              });
            }
            optionValues = Array.from(selectElement.options)
              .map((option) => option.value)
              .filter((value) => value !== "-------------");
            newDropdownList.push({
              dropdown_uuid: dropdown.dropdown_uuid,
              name: `dropdown ${dropdown.dropdown_uuid}`,
              selected_value: dropdown.selected_value,
              dropdowns_values: optionValues || [],
              isPasted: true,
            });
            selectElement.classList.add(`${id === "introTextTE" ? "intro" : id === "commentText" ? "comment" : "summary"}-cl-dropdown`);
            selectElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
            if (id !== "introTextTE") {
              selectElement.setAttribute("comment_id", commentData?.comment_id);
              selectElement.setAttribute("comment_uuid", commentData?.comment_uuid);
              selectElement.setAttribute("dropType", id === "commentText" ? "comment" : "summary");
            }
            editorData.innerHTML = editorData.innerHTML?.replace(regexPlain, selectElement.outerHTML);
          });
        }
      } else {
        const outputString = editorData?.innerHTML?.replace(regexPlainDropdown, (match, selectId) => {
          return `<select id="${selectId}" class="${id === "introTextTE" ? "intro" : id === "commentText" ? "comment" : "summary"}-cl-dropdown"><option>--------------</option></select>`;
        });
        editorData.innerHTML = outputString;
      }
      return decodeEntities(copy ? editorData.innerHTML : editorData.innerHTML?.replace(regexPlainDropdown, ""));
    };
    setTimeout(() => {
      if (!isEffectExecuted && (editorContent === undefined || copy)) {
        isEffectExecuted = true;
        const newHtmlData = replacePlaceholdersWithDropdowns();
        editorInstance.html.set(commentData?.comment_dropdowns?.length || commentData?.summary_comment_dropdowns?.length ? newHtmlData : editorData?.innerHTML);
        setState((prevState) => ({
          ...prevState,
          dropdownList:
            commentData?.comment_dropdowns?.length && id === "commentText"
              ? commentData?.comment_dropdowns
              : commentData?.summary_comment_dropdowns?.length && id === "commentSummary"
                ? commentData?.summary_comment_dropdowns
                : newDropdownList,
        }));
        handleModelChange(commentData?.comment_dropdowns?.length || commentData?.summary_comment_dropdowns?.length ? newHtmlData : editorData?.innerHTML);
      }
    }, 100);
    return () => {
      isEffectExecuted = false;
    };
  }, [commentData, id, copy]);

  const handleModelChange = (model) => {
    let htmlString = model;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const pastedElements = doc?.querySelectorAll(`[id^="isPasted"], [id^="pasted/"], #isPasted`);
    const spanElements = doc?.querySelectorAll("span:not([id]):has(select)");
    let checkPaste = false;
    if (pastedElements.length > 0) {
      pastedElements.forEach((element) => {
        if (element?.tagName === "SPAN" || element?.tagName === "SELECT") {
          if (element?.tagName === "SPAN") {
            const selectElement = element?.querySelector("select");
            if (selectElement) {
              checkPaste = true;
              element.remove();
            }
          } else if (element?.tagName === "SELECT") {
            checkPaste = true;
            element.remove();
          }
        } else if (element?.tagName === "BR" || element?.tagName === "P") {
          function findNextSpan(node) {
            while (node) {
              if (node?.tagName === "SPAN" || node?.tagName === "SELECT") return node;
              if (node?.tagName === "P") {
                const nestedSpan = node?.querySelector("span");
                if (nestedSpan) return nestedSpan;
                const nestedSelect = node?.querySelector("select");
                if (nestedSelect) return nestedSelect;
              }
              node = node.nextElementSibling;
            }
            return null;
          }
          const targetSpan = findNextSpan(element.nextElementSibling);
          if (targetSpan?.tagName === "SPAN") {
            const selectElement = targetSpan?.querySelector("select");
            if (selectElement) {
              checkPaste = true;
              selectElement.remove();
            }
          } else if (targetSpan?.tagName === "SELECT") {
            checkPaste = true;
            targetSpan.remove();
          }
        }
      });
      if (spanElements.length > 0) {
        spanElements.forEach((element) => {
          const selectElement = element?.querySelector("select");
          if (selectElement) {
            checkPaste = true;
            selectElement.remove();
          }
          const buttonElement = element?.querySelector("button");
          if (buttonElement) {
            checkPaste = true;
            buttonElement.remove();
          }
        });
      }
    }
    if (checkPaste) {
      setState((prevState) => ({ ...prevState, openRemoveDropdown: doc.body.innerHTML }));
    } else {
      onChange(id, model);
    }
  };

  let isHandlingImage = false;

  // const insertImageToEditor = (base64String) => {
  //   const editorInstance = editor.current.editor;
  //   const cursorPosition = editorInstance.selection.get();
  //   if (cursorPosition) editorInstance.image.insert(base64String, true);
  //   isHandlingImage = false;
  // };

  const handleImageBeforeUpload = (images) => {
    // if (images.length && !isHandlingImage) {
    //   isHandlingImage = true;
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     insertImageToEditor(e.target.result);
    //   };
    //   reader.readAsDataURL(images[0]);
    // }
    return false;
  };

  const handlePasteBefore = (e) => {
    if (isHandlingImage) return;
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        e.preventDefault();
        // isHandlingImage = true;
        // const blob = items[i].getAsFile();
        // if (!blob) {
        //   const reader = new FileReader();
        //   reader.onload = (event) => {
        //     insertImageToEditor(event.target.result);
        //   };
        // reader.readAsDataURL(blob);
        // }
      } else if (items[i].type.indexOf("text/html") !== -1) {
        items[i].getAsString((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          // const images = doc?.querySelectorAll("img");
          // images.forEach((img) => {
          //   const src = img.src;
          //   if (src.startsWith("data:image")) {
          // insertImageToEditor(src);
          //   }
          // });
          e.preventDefault();
          const images = doc?.querySelectorAll("img");
          images.forEach((img) => {
            const src = img.src;
            if (src.startsWith("data:image")) {
              img.parentNode.removeChild(img);
            }
          });
          doc?.querySelectorAll("img, figure, a").forEach((el) => el.remove());
          const elementsWithBgImages = doc?.querySelectorAll("[style]");
          elementsWithBgImages.forEach((el) => {
            const style = el.getAttribute("style");
            if (style && style.includes("url(")) {
              el.removeAttribute("style");
            }
          });
          const editorInstance = editor.current.editor;
          if (editorInstance) {
            setTimeout(() => {
              editorInstance.html.insert(doc.body.innerHTML, true);
            }, 100);
          }
        });
      }
    }
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    let newDropdownList = id === "introTextTE" ? commentData?.introductory_text_dropdowns : id === "commentText" ? commentData?.comment_dropdowns : commentData?.summary_comment_dropdowns;
    if (newDropdownList?.length === 0) newDropdownList = [...dropdownList];
    if (!isEdit) {
      let updatedDropdownList;
      updatedDropdownList = newDropdownList?.map((dropdown) => {
        if (dropdown.dropdown_uuid === event.target.id) {
          return { ...dropdown, selected_value: newValue || "" };
        }
        return dropdown;
      });
      handleDropdownList?.(id, updatedDropdownList, event.target.value);
      setState((prevState) => ({ ...prevState, dropdownList: updatedDropdownList }));
    } else {
      const index = newDropdownList.findIndex((obj) => obj?.dropdown_uuid === event.target.id);
      newDropdownList[index] = { ...newDropdownList[index], selected_value: newValue || "" };
      handleDropdownList?.(id, newDropdownList, event.target.value);
      setState((prevState) => ({ ...prevState, dropdownList: newDropdownList }));
    }
    const selectRegex = new RegExp(`<select[^>]*?id=["']${event.target.id}["'][\\s\\S]*?<\\/select>`, "g");
    const editorInstance = editor.current?.editor;
    let editorContent = editorInstance?.html?.get();
    editorContent = editorContent.replace(selectRegex, (selectMatch) => {
      const optionRegex = /<option([^>]*)>([^<]+)<\/option>/g;
      return selectMatch.replace(optionRegex, (optionMatch, attributes, value) => {
        const isSelected = value.trim() === newValue.trim();
        const updatedAttributes = attributes.replace(/selected/g, "").trim();
        return `<option ${updatedAttributes} ${isSelected ? "selected" : ""}>${value}</option>`;
      });
    });
    editorInstance.html.set(editorContent);
  };

  useEffect(() => {
    const editorInstance = editor.current?.editor;
    if (editorInstance) {
      const contentArea = editorInstance.$el.get(0);
      contentArea.addEventListener("click", handleOpenDialog);
      contentArea.addEventListener("change", handleSelectChange);
      return () => {
        contentArea.removeEventListener("click", handleOpenDialog);
        contentArea.removeEventListener("change", handleSelectChange);
      };
    }
  }, [editorData]);

  useEffect(() => {
    document.addEventListener("openDialog", handleOpenDialog);
    document.addEventListener("changeValue", handleSelectChange);
    return () => {
      document.removeEventListener("openDialog", handleOpenDialog);
      document.removeEventListener("changeValue", handleSelectChange);
    };
  }, []);

  const btnclick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let newDropdownList = [...dropdownList];
    const editorInstance = editor.current.editor;
    const cursorPosition = editorInstance.selection.get();
    const newSelectUuid = uuid();
    if (cursorPosition) {
      editorInstance.html.insert(
        ` <span contenteditable="false" id="[dropdown ${newSelectUuid}]">
            <select id="${newSelectUuid}"
                style="width: 104px;
                background-color: #eef5ff;
                height: 24px;
                overflow-wrap: break-word;
                padding: 1px 17px 3px 6px;
                background-position: right 4px center;
                background-repeat: no-repeat;
                background-size: 22px 20px;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #4282e2 !important;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                font-size: 14px !important;
                color: #3B3B3B !important;
                font-style: normal !important;
                font-weight: 600 !important;
                max-width: 200px !important;
                min-width: 104px !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
                width: -webkit-max-content !important;
                width: max-content !important;
                background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M7%2010L12%2015L17%2010H7Z%22%20fill%3D%22%23777777%22/%3E%3C/svg%3E');
                onchange="handleSelectChange(event, '${newSelectUuid}')"
                " >
                <option selected value="-------------">-------------</option>
            </select>
            <style>
              #dropdownButton${newSelectUuid} {
                background-color: #f1f9fe;
                width: 24px;
                height: 24px;
                padding: 0px;
                color: #0F75BC;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #0F75BC;
                position: relative;
                transform: scaleX(-1);
                font-size: 17px;
                margin-left: -4px;
                display: inline-flex;
                vertical-align: middle;
                top: -1px;
              }
              @media screen and (max-width: 1100px) {
                #dropdownButton${newSelectUuid} {
                  top: 25.5px;
                }
              }
              @media screen and (min-width: 1600px) {
                #dropdownButton${newSelectUuid} {
                  top: 2.5px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 1700px) {
                #dropdownButton${newSelectUuid} {
                  top: 2.5px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 1800px) {
                #dropdownButton${newSelectUuid} {
                  top: 2.5px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 1900px) {
                #dropdownButton${newSelectUuid} {
                  top: 2px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 2000px) {
                #dropdownButton${newSelectUuid} {
                  top: -1.5px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 2100px) {
                #dropdownButton${newSelectUuid} {
                  top: 50px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 2300px) {
                #dropdownButton${newSelectUuid} {
                  top: 50px;
                  width: initial;
                  margin-left: -5px;
                }
              }
               @media screen and (min-width: 2500px) {
                #dropdownButton${newSelectUuid} {
                  top: -2px !important;
                  width: initial;
                  margin-left: -5px;
                }
              }
            </style>
            <button
              id="dropdownButton${newSelectUuid}"
              contenteditable="false"
              name="${newSelectUuid}"
              classname="dropdown-button"
              type="button"
              onclick="handleOpenDialog(event, '${newSelectUuid}');"
              style=" background-color: #f1f9fe;
                width: 24px;
                height: 24px;
                padding: 0px;
                color: #0F75BC;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #0F75BC;
                position: relative;
                transform: scaleX(-1);
                font-size: 17px;
                margin-left: -4px;
                display: inline-flex;
                vertical-align: middle;
                top: -1px; "
            >
              ✎
            </button>
        </span>
        `,
        true
      );
    }
    newDropdownList.push({
      dropdown_uuid: newSelectUuid,
      name: `dropdown ${newSelectUuid}`,
      selected_value: "",
      dropdowns_values: [],
    });
    handleDropdownList?.(id, newDropdownList);
    setState((prevState) => ({ ...prevState, dropdownList: newDropdownList }));
    setTimeout(() => {
      const button = editor.current.editor.$el.find(`#dropdownButton${newSelectUuid}`);
      const select = editor.current.editor.$el.find(`#${newSelectUuid}`);
      button.on("click", function (event) {
        const customEvent = new CustomEvent("openDialog", { detail: { id: newSelectUuid, event: event } });
        document.dispatchEvent(customEvent);
      });
      select.on("change", function (event) {
        const customEvent = new CustomEvent("changeValue", { detail: { id: newSelectUuid, event: event } });
        document.dispatchEvent(customEvent);
      });
    }, 0);
  };

  const handleSave = () => {
    let index = dropdownList.findIndex((obj) => obj?.dropdown_uuid === selectedDropdown);
    if (index === -1) index = dropdownList.findIndex((obj) => obj?.isPasted === true);
    const editorInstance = editor.current.editor;
    let editorContent = editorInstance.html.get();
    const optionElements = dialogOptionList.map((option) => {
      const truncatedOption = option.length > 40 ? option.substring(0, 40) + "..." : option;
      const isSelected = dropdownList[index]?.selected_value === option;
      return `<option title="${option}" value="${option}" ${isSelected ? "selected" : ""}>${truncatedOption}</option>`;
    });
    const optionsString = optionElements.join("");
    const selectRegex = new RegExp(`<select[^>]*?id=["']${selectedDropdown}["'][\\s\\S]*?<\\/select>.*?`, "g");
    function replaceOptionsInSelect(selectHtml, newOptionsHtml) {
      const optionsRegex = /(<select[^>]*>)[\s\S]*?(<\/select>)/;
      return selectHtml.replace(optionsRegex, `$1${newOptionsHtml}$2`);
    }
    const newOptionsHtml = `<option value="-------------">-------------</option>${optionsString}`;
    const replacedContent = editorContent.replace(selectRegex, (match) => replaceOptionsInSelect(match, newOptionsHtml));
    editorInstance.html.set(replacedContent);
    const newDialogOptionList = dialogOptionList.filter((option) => option.trim() !== "");
    let newDropdownOptions = [...dropdownList];
    if (newDropdownOptions.length === 0) {
      newDropdownOptions.push({ dropdown_uuid: selectedDropdown, selected_value: "", dropdowns_values: newDialogOptionList?.length === 0 ? ["------------"] : newDialogOptionList });
    } else {
      newDropdownOptions[index] = {
        ...newDropdownOptions[index],
        dropdowns_values: newDialogOptionList || [],
      };
    }
    handleDropdownList?.(id, newDropdownOptions);
    onChange(id, editorInstance.html.get());
    setState((prevState) => ({ ...prevState, open: false, dialogOptionList: [], dropdownList: newDropdownOptions }));
  };

  const openHelpDialog = () => {
    setState((prevState) => ({ ...prevState, openHelp: !openHelp }));
  };

  Froalaeditor.DefineIcon("aiHelper", { SRC: BulbIcon, ALT: "Image button", height: 50, width: "50px", template: "image" });
  Froalaeditor.RegisterCommand("aiHelper", {
    title: "AI helper",
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      openHelpDialog();
    },
  });

  const handleOutsideClick = async () => {
    const editorInstance = editor.current?.editor;
    const editorContent = editorInstance?.html?.get();
    if (onBlur && storeData?.editorListner) {
      await onBlur(id, editorContent);
      setState((prevState) => ({ ...prevState, isFocused: false }));
    }
  };
  const handlePasteAfter = () => {
    const parser = new DOMParser();
    const editorInstance = editor?.current?.editor;
    const editorData = editorInstance?.html?.get();
    const document = parser.parseFromString(editorData, "text/html");
    const selects = document.querySelectorAll("select")
    if (selects?.length > 0) {
      setState((prevState) => ({ ...prevState, openRemoveDropdown: editorData }))
    }
  }

  const setEditorData = (data, type) => {
    const editorInstance = editor?.current?.editor;
    const parser = new DOMParser();
    const document = parser.parseFromString(editorInstance?.html?.get(), "text/html");
    document.querySelectorAll("span").forEach((span) => {
      const hasSelect = span.querySelector("select");
      const hasButton = span.querySelector("button");
      const otherContent = span.cloneNode(true);
      otherContent.querySelectorAll("select, button").forEach((child) => child.remove());
      if (otherContent.innerHTML.trim()) {
        const pTag = document.createElement("p");
        pTag.innerHTML = otherContent.innerHTML;
        span.parentNode.insertBefore(pTag, span.nextSibling);
      }
      if (!hasSelect?.id || !hasButton?.id) {
        hasSelect?.remove();
        hasButton?.remove();
        span.remove();
      }
    });
    document.querySelectorAll("select:not([id]), select[id='isPasted']").forEach((select) => {
      const nextSibling = select.nextElementSibling;
      if (!select?.id) select.remove();
      if (nextSibling && nextSibling?.tagName.toLowerCase() === 'button') {
        nextSibling.remove();
      }
    });
    document.querySelectorAll("p, div, span").forEach((el) => {
      if (!el.textContent.trim()) {
        el.remove();
      }
    });
    const pastedElements = document?.querySelectorAll(`[id^="isPasted"], [id^="pasted/"], #isPasted`);
    if (type === "cancel") {
      if (pastedElements?.length > 0) {
        const pastedDoc = parser.parseFromString(data, "text/html");
        pastedElements.forEach((element) => {
          if (element?.tagName === "BR" || element?.tagName === "P") {
            function findNextSpan(node) {
              while (node) {
                if (node?.tagName === "SPAN" || node?.tagName === "SELECT") return node;
                if (node?.tagName === "P") {
                  const nestedSpan = node?.querySelector("span");
                  if (nestedSpan) return nestedSpan;
                  const nestedSelect = node?.querySelector("select");
                  if (nestedSelect) return nestedSelect;
                }
                node = node.nextElementSibling;
              }
              return null;
            }
            const targetSpan = findNextSpan(element.nextElementSibling);
            if (targetSpan?.tagName === "SPAN") {
              const selectElement = targetSpan?.querySelector("select");
              if (selectElement) {
                selectElement.remove();
              }
            } else if (targetSpan?.tagName === "SELECT") {
              targetSpan.remove();
            }
          }
        })
        pastedDoc.querySelectorAll("select:not([id])").forEach((select) => {
          const nextSibling = select.nextElementSibling;
          if (!select?.id) select.remove();
          if (nextSibling && nextSibling?.tagName.toLowerCase() === 'button') {
            nextSibling.remove();
          }
        });
        pastedDoc.querySelectorAll("button:not([id])").forEach((button) => {
          if (!button?.id) button.remove();
        });
        onChange(id, pastedDoc.body.innerHTML);
        editorInstance?.html?.set(pastedDoc.body.innerHTML);
      } else {
        onChange(id, document.body.innerHTML);
        editorInstance?.html?.set(document.body.innerHTML);
      }
    } else {
      if (pastedElements?.length > 0) {
        pastedElements.forEach((element) => {
          const textContent = element.textContent.trim();
          if (textContent) {
            const textNode = document.createTextNode(textContent);
            element.parentNode.replaceChild(textNode, element);
          } else {
            element.remove();
          }
        });
      }
      onChange(id, document.body.innerHTML);
      editorInstance?.html?.set(document.body.innerHTML);
    }
  };

  const handleCloseRemoveDropdown = () => {
    setEditorData(editorData?.innerHTML, "cancel");
    setState((prevState) => ({ ...prevState, openRemoveDropdown: false }));
  };

  const handleSubmitRemoveDropdown = () => {
    setEditorData(null, "paste");
    setState((prevState) => ({ ...prevState, openRemoveDropdown: false }));
  };

  return (
    <div id={id} style={{ width: "100%" }}>
      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => isFocused && handleOutsideClick()}>
        <FroalaEditor
          id={id}
          ref={editor}
          value={editorData}
          onModelChange={handleModelChange}
          config={{
            key: "1CC3kA6C7A5A3B3A3E3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3C7A4A4A4G3H3B1==",
            placeholderText: id === "introTextTE" ? "Add informational text" : " ",
            charCounterCount: false,
            quickInsertEnabled: false,
            events: {
              // "image.beforeUpload": handleImageBeforeUpload,
              // "paste.before": handlePasteBefore,
              // focus: handleFocus,
              // blur: handleBlur,
              "paste.after": handlePasteAfter,
            },
            ...froalaConfig,
          }}
        />
      </ClickAwayListener>
      {isEdit !== undefined && isEdit === false && !location.pathname.includes("templates") ? (
        <Grid display={"flex"} className="add-dropdown-box">
          <Button className="add-dropdown-typo" onClick={(event) => btnclick(event)} disableRipple startIcon={<AddCircleOutlineOutlinedIcon className="add-dropdown-circle" />}>
            Add dropdown
          </Button>
        </Grid>
      ) : null}
      <EditOptionsDialog id={id} state={state} setState={setState} handleSave={handleSave} />
      {openRemoveDropdown && <CopySummaryModal
        open={openRemoveDropdown}
        handleClose={handleCloseRemoveDropdown}
        handleSubmit={handleSubmitRemoveDropdown}
        submitButtonTitle={"Paste Without Dropdown"}
        dialogTitle={"Dropdown Won’t Be Included"}
      />}
    </div>
  );
};

export default FroalaEditorComponent;
